import { FormEvent, useState } from 'react'

import './styles.css'

import mapMarker from '../../assets/icons/map__marker.png'
import designPhone from '../../assets/icons/phone.png'
import emailIcon from '../../assets/icons/email.png'

export default function Footer() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const [formReturn, setFormReturn] = useState(false)

  const [mailSended, setMailSended] = useState<boolean>()

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()

    await fetch('https://central.brst.com.br:8080/mail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        website: 'brst.com.br',
      },
      body: JSON.stringify({
        name,
        email,
        message,
        phone,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setMailSended(true)
          setFormReturn(true)

          setTimeout(() => {
            setFormReturn(false)
          }, 5000)
        } else {
          setMailSended(false)
          setFormReturn(true)

          setTimeout(() => {
            setFormReturn(false)
          }, 5000)
        }
      })
      .catch((err) => {
        setMailSended(false)
        setFormReturn(true)

        setTimeout(() => {
          setFormReturn(false)
        }, 5000)
      })
  }

  return (
    <footer id="footer">
      <div className="container">
        <div className="contact">
          <h1>Fale conosco</h1>
          <form onSubmit={(e) => handleSubmit(e)}>
            <input
              onChange={(e) => setName(e.target.value)}
              name="name"
              required
              type="text"
              placeholder="Nome"
            />
            <input
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              required
              type="email"
              placeholder="E-mail"
            />
            <input
              onChange={(e) => setPhone(e.target.value)}
              name="phone"
              required
              type="text"
              placeholder="Telefone"
            />
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              name="message"
              required
              placeholder="Mensagem"
            />

            {formReturn && (
              <div
                className="form__return"
                style={
                  mailSended
                    ? { backgroundColor: '#00A62E' }
                    : { backgroundColor: '#FF4040' }
                }
              >
                {mailSended ? (
                  <h4>
                    E-mail enviado com sucesso, entraremos com contato com você
                    em breve!
                  </h4>
                ) : (
                  <h4>
                    Erro ao enviar o e-mail, você pode entrar em contato
                    clicando{' '}
                    <a href="https://wa.me/556699984060?text=Olá, estou entrando em contato pelo site da Brasil Sistemas.">
                      aqui
                    </a>
                    !
                  </h4>
                )}
              </div>
            )}

            <button type="submit">Enviar</button>
          </form>
        </div>

        <div className="find-us">
          <div className="type">
            <div className="title">
              <img src={mapMarker} alt="Marcador Mapa" />

              <span>Endereço</span>
            </div>

            <div className="details">
              <span>
                Rua das Primaveras, N° 1537 <br />
              </span>
              <span>Jardim Jacarandas</span>
              <span>CEP: 78557-730 - Sinop - MT</span>
            </div>
          </div>

          <div className="type">
            <div className="title">
              <img src={designPhone} alt="Telefone" />

              <span>Telefones</span>
            </div>

            <div className="details">
              <span>(66) 99998-4060</span>
            </div>
          </div>

          <div className="type">
            <div className="title">
              <img src={emailIcon} alt="Email" />

              <span>Email</span>
            </div>

            <div className="details">
              <span>comercial@brst.com.br</span>
            </div>
          </div>
        </div>
      </div>

      <div className="property">
        <h3>© 2022 RuralHub</h3>
      </div>
    </footer>
  )
}
